<template>
    <div class="card">
        <el-form ref="managerForm" :model="managerData" :rules="managerDataRules" label-width="250px">
            <el-row :gutter="15">
                <el-col :span="16" :offset="1">
                    <el-form-item :label="$t('employees.name')" prop="name">
                        <el-input v-model="managerDataCopy.name" class="pl-3" @change="onChange" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.surname')" prop="surname">
                        <el-input v-model="managerDataCopy.surname" class="pl-3" @change="onChange" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.email_login_as_well')" prop="email">
                        <el-input v-model="managerDataCopy.email" autocomplete="new-password" class="pl-3" @change="onChange" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.password')" prop="password">
                        <el-input v-model="managerDataCopy.password" type="password" :show-password="true" autocomplete="new-password" class="pl-3" @change="onChange" />
                    </el-form-item>
                    <el-form-item label="Personnummer">
                        <el-input v-model="managerDataCopy.personnummer" class="pl-3" @change="onChange" />
                    </el-form-item>
                    <el-form-item :label="$t('employees.telephone_number')">
                        <el-input v-model="managerDataCopy.telephone_number" class="pl-3" @change="onChange" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </div>
</template>
<script>
export default {
    props: {
        managerData: {
            type: Object,
            default() {
                return {
                    name:                 null,
                    surname:              null,
                    login:                null,
                    email:                null,
                    telephone_number:     null,
                    password:             null,
                    manager_personnummer: null,
                };
            },
        },
    },

    data() {
        return {
            managerDataRules: {
                name:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                surname: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                email:   [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { type: 'email', message: this.$t('common.wrong_email_format'), trigger: 'blur' },
                ],
                password: [
                    { required: true, message: this.$t('common.required'), trigger: 'blur' },
                    { min: 5, message: this.$t('common.min_5_characters'), trigger: 'blur' },
                ],
            },
            managerDataCopy: { ...this.managerData },
        };
    },

    methods: {
        onChange() {
            this.$emit('onChange', this.managerDataCopy);
        },
    },
};
</script>
<style scoped>
    .el-select {
        width: 100%;
    }
</style>
<style>
.el-form-item__error {
    padding-left: 12px;
}
</style>
