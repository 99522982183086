<template>
    <div class="pb-3">
        <el-row>
            <el-col :span="16" :offset="4">
                <div class="card">
                    <el-steps :active="activeStep" finish-status="success" align-center>
                        <el-step :title="$t('managers.general_information')" />
                        <el-step :title="$t('managers.address')" />
                    </el-steps>
                </div>
            </el-col>
        </el-row>
        <el-row>
            <el-col v-show="activeStep === 0" :span="16" :offset="4">
                <StepOne ref="stepOne" :managerData="managerData" @onChange="onChangeStepOne" />
            </el-col>

            <el-col v-show="activeStep === 1" :span="16" :offset="4">
                <StepTwo ref="stepTwo" :addressData="addressData" />
            </el-col>

            <el-col :span="16" :offset="4">
                <el-button type="primary" :disabled="activeStep <= 0" @click="previousStep">
                    {{ $t('managers.previous') }}
                </el-button>
                <el-button type="primary" :disabled="activeStep >= 1" @click="nextStep">
                    {{ $t('common.next') }}
                </el-button>
                <el-button v-if="activeStep === 1" type="success" :loading="$wait.is('creating')" @click="nextStep">
                    {{ $t('common.finish') }}
                </el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import StepOne from './components/ManagersCreateStepOne.vue';
import StepTwo from './components/ManagersCreateStepTwo.vue';

export default {
    components: { StepOne, StepTwo },

    data() {
        return {
            activeStep:  0,
            managerData: {},
            addressData: {
                street:    null,
                post_code: null,
                city:      null,
                map_lat:   59.3260664,
                map_lng:   17.8471233,
            },
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('managers.add_new_manager'));
        this.$store.commit('setActiveMenuItem', '4-2');
    },

    methods: {
        async createManagerRequest() {
            this.$wait.start('creating');
            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/managers/`, {
                    // Manager data
                    manager_name:             this.managerData.name,
                    manager_surname:          this.managerData.surname,
                    manager_email:            this.managerData.email,
                    manager_password:         this.managerData.password,
                    manager_telephone_number: this.managerData.telephone_number,
                    manager_personnummer:     this.managerData.personnummer,

                    // Address data
                    address_co:        this.addressData.co,
                    address_street:    this.addressData.street,
                    address_post_code: this.addressData.post_code,
                    address_city:      this.addressData.city,
                    address_map_lat:   this.addressData.map_lat,
                    address_map_lng:   this.addressData.map_lng,
                });
                this.$notify.success({ title: 'Success' });
                this.$wait.end('creating');
                this.$router.push({ name: 'managers_all' });
            } catch (error) {
                this.$wait.end('creating');
            }
        },

        nextStep() {
            if (this.activeStep > 1) return;

            if (this.activeStep === 0) {
                this.$refs.stepOne.$refs.managerForm.validate(valid => {
                    if (valid) {
                        this.activeStep++;
                    }
                });
                return;
            }

            if (this.activeStep === 1) {
                this.$refs.stepTwo.$refs.addressForm.validate(valid => {
                    if (valid) {
                        this.createManagerRequest();
                    }
                });
            }
        },

        previousStep() {
            if (this.activeStep <= 0) return;
            this.activeStep--;
        },

        onChangeStepOne(value) {
            this.managerData = value;
        },
    },
};
</script>
