<template>
    <div class="card">
        <el-form ref="addressForm" label-position="top" :model="addressData" :rules="addressDataRules">
            <el-row>
                <el-col :span="24">
                    <el-form-item :label="$t('common.google_map_search')">
                        <AutoComplete @placeFound="placeFound" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item label="CO">
                        <el-input v-model="addressData.co" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('employees.street')" prop="street">
                        <el-input v-model="addressData.street" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="12">
                    <el-form-item :label="$t('employees.city')" prop="city">
                        <el-input v-model="addressData.city" />
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item :label="$t('employees.post_code')" prop="post_code">
                        <el-input v-model="addressData.post_code" />
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="google-maps-container">
                <gmap-map
                    ref="maps"
                    :center="{ lat:Number(marker.map_lat), lng:Number(marker.map_lng) }"
                    :zoom="14"
                    map-type-id="terrain"
                    style="height: 363px"
                    :options="{
                        scrollwheel: false,
                    }"
                >
                    <gmap-marker
                        :key="10"
                        :position="{ lat:Number(addressData.map_lat), lng:Number(addressData.map_lng)}"
                        :clickable="false"
                    />
                </gmap-map>
            </div>
        </el-form>
    </div>
</template>
<script>

export default {
    components: {
        AutoComplete: () => import(/* webpackChunkName: "GoogleAddressAutoComplete" */ '@/components/GoogleMapSearch/AutoComplete.vue'),
    },

    props: {
        addressData: {
            type:    Object,
            default: () => ({
                co:        null,
                street:    null,
                post_code: null,
                city:      null,
                map_lat:   59.3260664,
                map_lng:   17.8471233,
            }),
        },
    },

    data() {
        return {
            addressDataRules: {
                street:    [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                post_code: [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
                city:      [{ required: true, message: this.$t('common.required'), trigger: 'blur' }],
            },
            marker: {
                map_lat: 59.3260664,
                map_lng: 17.8471233,
            },
        };
    },

    methods: {
        placeFound(data) {
            this.addressData.post_code = data.postcode;
            this.addressData.city = data.city;
            this.addressData.street = data.street;
            this.addressData.map_lat = data.map_lat;
            this.addressData.map_lng = data.map_lng;
            this.marker.map_lat = data.map_lat;
            this.marker.map_lng = data.map_lng;

            this.$refs.addressForm.validate(() => {});
        },

        markerDragStart(event) {
            this.$set(this.addressData, 'map_lat', event.latLng.lat());
            this.$set(this.addressData, 'map_lng', event.latLng.lng());
        },
    },
};
</script>
<style scoped>
    .el-select {
        width: 100%;
    }
</style>
